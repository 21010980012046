.custom-shape-divider-bottom-1692616585 {
    margin-top: 4%;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1692616585 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 150px;
}

.custom-shape-divider-bottom-1692616585 .shape-fill {
    fill: #797a7a;
}